<style lang="scss" scoped>
.list {
  position: relative;
  width: calc( 100% - 20px );
  height: calc( 100% - 70px );
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin: 10px;
  overflow-y: auto;

  .item {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-top: #fff solid 1px;
    border-bottom: #fff solid 1px;
    cursor: pointer;
    transition: all 250ms;

    &:nth-child(2n) {
      background-color: rgba(255, 255, 255, 0.95);
      border-top: rgba(255, 255, 255, 0.95) solid 1px;
      border-bottom: rgba(255, 255, 255, 0.95) solid 1px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
      border-top: rgba(255, 255, 255, 0.9) solid 1px;
      border-bottom: rgba(255, 255, 255, 0.9) solid 1px;
    }

    &.selected {
      border-top: #BBDEFB solid 1px;
      border-bottom: #BBDEFB solid 1px;
      background-color: #E3F2FD;

      img {
        width: 10px;
        margin-right: 4px;
        transform: scaleX(1);
      }
    }

    img {
      width: 0px;
      margin-right: 0px;
      transform: scaleX(0);
      height: 10px;
      transition: all 250ms;
    }

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 10px;
    }

    .date {
      white-space: nowrap;
      font-size: 12px;
      color: #616161;
    }
  }
}

</style>
<script setup>

const props = defineProps({
  selectedTileId: {
    Type: String,
    default: ""
  },

  tileList: {
    Type: Array,
    default: () => []
  }
})

const emits = defineEmits(['selectTile'])

const methods = {
  selectTile: (tile) => {
    if(tile.id !== props.selectedTileId) {
      emits('selectTile', tile)
    }
  }
}

</script>

<template>
  <div class="list">
    <div class="item" :class="{selected: props.selectedTileId === tile.id}" v-for="(tile) in props.tileList" :key="tile.id" @click="methods.selectTile(tile)">
      <span class="name">
        <img alt="selected" src="@/assets/images/arrow.png">
        <span>{{ tile.dxfFileName }}</span>
      </span>
      <span class="date">{{ tile.createDate.substring(0,10) }}</span>
    </div>
  </div>
</template>